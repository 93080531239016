import React, { FC, useState } from 'react';

import styled from 'styled-components';
import { smMobile, tablet } from 'styles/breakpoints';
import { Reviews } from 'components';
import PaymentSteps from 'pages/start/payments/components/PaymentSteps';

import { useSelector } from 'react-redux';
import { AppState } from 'state/types';
import { getLocalisedProduct } from 'utils/localization';

import { Product } from 'types/product';
import BannerOne from '../BannerOne';
import BundlePlanCard from '../BundlePlanCard';
import UpsellList from '../UpsellList';

interface UpsellBundleProps {
  headline: string;
  label: string;
  title: string;
  handleUpgradeClick?: () => void;
  handleCancelClick?: () => void;
  bannerOne: {
    title: string;
    iconUrl: string;
  };
  paymentSteps: {
    firstStepTitle: string;
    secondStepTitle: string;
    thirdStepTitle: string;
  };
  reviewsSection: {
    title: string;
    reviews: string[];
  };
  planSection: PlanSection;
  planSection2: PlanSection;
  upsellsImages: string[];
  bundleProductsKeys: string[];
  disabled: boolean;
  upsellList: UpsellItem;
  localisedProduct?: Product;
  errorMessage?: string;
}

interface UpsellItem {
  image: string;
  title: string;
  list: { title: string; label: string }[];
}
interface PlanSection {
  cardLabel: string;
  cardBgColor: string;
  bgColor: string;
  name: string;
  hideCardBorder: boolean;
  yesButtonTitle: string;
  noButtonTitle: string;
  imgWidth: string;
  imgMargin: string;
  imgUrl: string;
  discountPercentText: string;
  price: number;
}
const PaymentStepsContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 2.5rem 1rem 1.5rem;
  width: 100%;
  background-color: #f3f3f8;
  @media ${tablet} {
    padding: 2.5rem 1rem;
  }
`;

const PaymentStepsStyled = styled(PaymentSteps)`
  width: 100%;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: #f3f3f8;
  @media ${tablet} {
    padding: 0 1rem;
  }
`;
const Headline = styled.p`
  color: #000;
  text-align: center;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.8125rem;
  padding: 3rem 1rem 0.5rem;
  max-width: 28.75rem;
  width: 100%;
  @media ${tablet} {
    padding: 1.5rem 1rem 0.5rem !important;
  }
`;

const Label = styled.p`
  color: #4d4d4d;
  text-align: center;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  max-width: 28.75rem;
  width: 100%;
  @media ${tablet} {
    padding-bottom: 1.25rem;
  }
`;

const TitleContainer = styled.div`
  background-color: #fff;
  width: 100%;
`;

const Title = styled.p`
  color: #1c1c28;
  text-align: center;
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 4rem;
  margin: 0 auto;
  padding: 2.375rem 1rem 0rem 1rem;
  @media ${tablet} {
    font-size: 1.5rem;
    line-height: 1.8125rem;
    padding: 2.25rem 1rem 0rem 1rem;
  }
`;

const ReviewContainer = styled.div`
  background-color: #f3f3f8;
  width: 100%;
`;

const PaddingHelper = styled.div`
  background-color: #f6f4ef;
  height: 1.5rem;
  width: 100%;
`;

const PlanCardContainer = styled.div<{ bgColor?: string }>`
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: ${({ bgColor }) => (bgColor ? bgColor : '#f6f4ef')};
`;

const UpsellBundle: FC<UpsellBundleProps> = ({
  localisedProduct,
  bannerOne,
  headline,
  label,
  paymentSteps,
  reviewsSection,
  planSection,
  planSection2,
  handleUpgradeClick,
  handleCancelClick,
  upsellsImages,
  bundleProductsKeys,
  upsellList,
  title,
  disabled,
  errorMessage,
}) => {
  const { upsell_products } = useSelector((state: AppState) => state.user);
  const products = useSelector(
    (state: AppState) => state.funnel.products
  ) as Product[];

  const boughtUpsell = upsell_products.find(item =>
    bundleProductsKeys.includes(item.key)
  );

  const sortedUpsells = bundleProductsKeys
    .map(key => products.find(item => item.key === key))
    .filter(item => item !== undefined && item.key !== boughtUpsell?.key);

  const localisedUpsells = sortedUpsells.map(upsell =>
    getLocalisedProduct(upsell as Product)
  );

  let totalPrice = 0;
  for (let i = 0; i < localisedUpsells.length; i++) {
    totalPrice += Number(localisedUpsells[i].regularPrice);
  }

  const image = boughtUpsell
    ? upsellsImages[boughtUpsell.key]
    : upsellsImages['default'];

  const regularPrice = Number(localisedProduct?.finalPrice);
  const discountSize = ((totalPrice - regularPrice) / totalPrice) * 100;
  const fixedDiscountSize = discountSize.toFixed();

  const updatedPlanSection = {
    ...planSection,
    cardInnerImage: image,
    savings: fixedDiscountSize,
    oldPrice: totalPrice.toFixed(2),
    currency: localisedProduct?.currency,
  };
  const updatedPlanSection2 = {
    ...planSection2,
    cardInnerImage: image,
    savings: fixedDiscountSize,
    oldPrice: totalPrice.toFixed(2),
    currency: localisedProduct?.currency,
  };

  return (
    <>
      <PaymentStepsContainer>
        <PaymentStepsStyled {...paymentSteps} />
      </PaymentStepsContainer>
      <TextContainer>
        <Headline>{headline}</Headline>
        <Label>{label}</Label>
      </TextContainer>
      <PlanCardContainer bgColor={updatedPlanSection.bgColor}>
        <BundlePlanCard
          product={localisedProduct}
          disabled={disabled}
          onUpgradeClick={handleUpgradeClick}
          onCancelClick={handleCancelClick}
          errorMessage={errorMessage}
          {...updatedPlanSection}
        />
      </PlanCardContainer>
      <TitleContainer>
        <Title>{title}</Title>
      </TitleContainer>
      {sortedUpsells.map((upsell, index) => (
        <UpsellList
          key={index}
          paddingTop={index === 0 ? 0.75 : 2}
          {...upsellList[upsell.key]}
          bgColor={index % 2 ? '#F3F3F8' : '#fff'}
        />
      ))}

      <PlanCardContainer
        bgColor={sortedUpsells.length % 2 ? '#fff' : '#F3F3F8'}
      >
        <BundlePlanCard
          product={localisedProduct}
          disabled={disabled}
          onUpgradeClick={handleUpgradeClick}
          onCancelClick={handleCancelClick}
          errorMessage={errorMessage}
          cardBgColor={sortedUpsells.length % 2 ? '#F3F3F8' : '#fff'}
          {...updatedPlanSection2}
        />
      </PlanCardContainer>
      <ReviewContainer>
        <Reviews {...reviewsSection} />
      </ReviewContainer>
    </>
  );
};

export default UpsellBundle;
